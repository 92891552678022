import { Stack, Text } from "@chakra-ui/react";
import AppCard from "./AppCard";

const AboutCard = () => (
  <AppCard>
    <Stack 
      spacing={4}
      p="20px"
    >
      <Text
        mt="-10px"
        fontFamily="Times New Roman, serif"
        fontSize="2xl"
        fontWeight="600"
      >
        About
      </Text>
      <Text
        fontFamily="Times New Roman, serif"
        fontSize={"xl"}
        // textAlign="justify"
      >
     We are an Ontario-based software development company specialized in web applications. 
     From healthcare to businesses, we aim to bring high-quality, reliable, and affordable software solutions to our clients. 
     In addition to fulfilling custom contracts, we are working to bring our own line of products to the market!
      </Text>
    </Stack>
  </AppCard>
)
export default AboutCard;