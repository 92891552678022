import { Flex } from "@chakra-ui/react"
import { Outlet } from "react-router-dom"

const Main = () => {
  return (
    <Flex 
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bg={'white'}
    >
      <Outlet />
    </Flex>
  )
}

export default Main