import * as React from "react"
import {
  ChakraProvider,
  theme,
} from "@chakra-ui/react"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Main from "./pages/Main";
import Landing from "./views/Landing";
import { useMediaQuery } from "react-responsive";
import MobileMain from "./mobile/views/MobileMain";

export const Context = React.createContext({});

export const App = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 48em)` });

  return (
  !isMobile ? (<Router>
    <ChakraProvider theme={theme}>
      <Routes>
          <Route path="/" element={<Main />}>
            <Route path="" element={<Landing />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />}/>
      </Routes>
    </ChakraProvider>
  </Router>) : (<Router>
    <ChakraProvider theme={theme}>
      <Routes>
          <Route path="/" element={<MobileMain />} />
          <Route path="*" element={<Navigate to="/" />}/>
      </Routes>
    </ChakraProvider>
  </Router>)
)}
