import { Button, Popover, PopoverBody, PopoverContent, PopoverTrigger, Stack, Text } from "@chakra-ui/react";

const PrivacyPopover = () => {
  return (
    <Popover
      placement="left"
    >
      <PopoverTrigger>
        <Button
              bg="#A0A0A0"
              color="white"
        >Privacy Notice</Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <Stack>
            <Text>We do not use or store your personal information in any way beyond what is necessary to respond to the above inquiry.</Text>
            <Text>If you would prefer to email us directly, we can be reached at:</Text>
            <Text
              fontWeight={"bold"}
              textAlign={"center"}
            
            >office@gdisoftware.com</Text>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default PrivacyPopover;