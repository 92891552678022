import { ReactChild } from "react";
import { animated, config, useSpring } from "react-spring";

interface ISimpleFadeProps {
  delay: number;
  width?: string;
  children: ReactChild;
}

const SimpleFade = ({ delay, width, children }: ISimpleFadeProps) => {
  const props = useSpring({ 
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay,
    config: config.molasses,
   });

   return (
      <animated.div
        style={{ width,...props }}
      >
        {children}
      </animated.div>
   )
};

export default SimpleFade