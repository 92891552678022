import { Stack, Text } from "@chakra-ui/react";
import AppCard from "./AppCard";
import ContactForm from "./ContactForm";
import { useState } from "react";
import { get } from 'lscache'

const ContactCard = () => {
  // const cachedContacted = get('contacted');
  const [contacted, setContacted] = useState(get('contacted') === 'OK');

  return (
    <AppCard
    // bg="#A0A0A0"
    bg="black"
    w="100%"
  >
    <Stack
      p="20px"
      w="100%"
      spacing={4}
    >
      <Text
        mt="-10px"
          fontFamily="Times New Roman, serif"
          fontSize="2xl"
          fontWeight="600"
          color="white"
        >
        Contact
      </Text>
      { !contacted ? <ContactForm 
        setContacted={setContacted}
      /> : (
        <Stack>
          <Text
            fontFamily="Times New Roman, serif"
            color="white"
            fontSize="xl"
            textAlign={'center'}
          >
            Thank-you for reaching out, we will get back to you as soon as possible!
          </Text>
          <Text
            fontFamily="Times New Roman, serif"
            color="white"
            fontSize="xl"
            textAlign={'center'}
            fontStyle="italic"
          >
            Have more to say? We can be reached at: <strong>office@gdisoftware</strong>
          </Text>
        </Stack>
      ) }
    </Stack>
  </AppCard>
  )

}

export default ContactCard;