import { Flex, HStack, IconButton, Image } from "@chakra-ui/react";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import masterTextLogo from '../assets/master-text-logo.svg';

const Footer = () => {
  return (
    <HStack
      position="fixed"
      bottom="0"
      left="0"
      right="0"
      justify="space-between"
      p="20px"
    >
      <Image src={masterTextLogo} width="500px"/>
      <Flex>

      <IconButton
        as='a'
        href='https://www.linkedin.com/company/gdisoftware/'
        target={'_blank'}
        aria-label="linkedin"
        icon={<FaLinkedin />}
        color="white"
        background="black"
        borderRadius="full"
        border="1.5px solid white"
        mx="1"
      />
      <IconButton
        as='a'
        href='https://github.com/GeneralDynamicInformationSoftware'
        target={'_blank'}
        aria-label="github"
        icon={<FaGithub />}
        color="white"
        background="black"
        borderRadius="full"
        border="1.5px solid white"
        mx="1"
      />
      </Flex>
    </HStack>
)};

export default Footer;