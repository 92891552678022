import { Stack } from "@chakra-ui/react";
import AboutCard from "../../components/AboutCard";
import AppBar from "../../components/AppBar";
import ContactCard from "../../components/ContactCard";
import MobileFooter from "../components/MobileFooter";
const MobileMain = () => {
  return (
    <>
      <AppBar />
            <Stack
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        bg="gray.50"
        overflowY="scroll"
        spacing={4}
        mt="70px"
      >

        <Stack
          p="15px"
          spacing={4}
          w="100%"
        >
          <ContactCard />
          <AboutCard />
        </Stack>


        <MobileFooter></MobileFooter>
      </Stack>
    </>
    
  )
}

export default MobileMain;