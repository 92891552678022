import { Button, HStack, Input, Stack, Textarea } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdSend } from "react-icons/md";
import mailerServiceModule from "../services/mailer.service";
import { set } from 'lscache';
import { useMediaQuery } from "react-responsive";
import PrivacyPopover from "./PrivacyPopover";
import PrivacyDrawer from "./PrivacyDrawer";

interface IContactFormProps {
  setContacted: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContactForm: React.FC<IContactFormProps> = ({ setContacted }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 48em)` });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const submit = async () => {
    setIsLoading(true);
    const mailerService = mailerServiceModule();
    const { errorMessage, data } = await mailerService.contact({
      firstName,
      lastName,
      email,
      message
    });

    if (errorMessage) {
      console.log(errorMessage);
      setIsLoading(false);
    } else {
      set('contacted', data, 10080);
      setFirstName('');
      setLastName('');
      setEmail('');
      setMessage('');
      setIsLoading(false);
      setContacted(true);
    }
  };

  useEffect(() => {
    const getValidity = () => {
      setIsValid(firstName.length > 0 && lastName.length > 0 && email.length > 0 && message.length > 0);
    }
    getValidity();
  }, [firstName, lastName, email, message]);

  return (
    <form 
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      <Stack
        spacing={4}
      >
        { !isMobile ? (<HStack>
          <Input
            bg="white"
            placeholder='First Name'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
          />
          <Input
            bg="white"
            placeholder='Last Name'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            type="text"
          />
        </HStack>) : (<>
          <Input
            bg="white"
            placeholder='First Name'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
          />
          <Input
            bg="white"
            placeholder='Last Name'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            type="text"
          />
      
        </>)}
        <Input
          bg="white"
          placeholder='Email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
        />
        <Textarea
          bg="white"
          placeholder='Message'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        { isMobile && <PrivacyDrawer />}
        <HStack
          justify={'flex-end'}
        >
          { !isMobile && <PrivacyPopover/>}

          <Button
            width={isMobile ? "100%" : "auto"}
            isLoading={isLoading}
            spinnerPlacement="end"
            type="submit"
            disabled={!isValid || isLoading}
            rightIcon={<MdSend
              style={{ transform: "rotate(-45deg)", marginTop: "-3px"}}
            />}
          >Send</Button>
        </HStack>
      </Stack>
    </form>
  )
}

export default ContactForm;