import { Button, Stack } from "@chakra-ui/react";
import { FaGithub, FaLinkedin } from "react-icons/fa";

const MobileFooter = () => {
  return (
    <Stack
      left="0"
      right="0"
      bottom="0"
      p="0"
      m="0"
      spacing="0"
      w="100%"
    >
      <Button
        bg="black"
        borderRadius={0}
        color="white"
        borderBottom="1px solid rgba(255,255,255,0.5)"
        as="a"
        href='https://www.linkedin.com/company/gdisoftware/'
        target={'_blank'}
        leftIcon={<FaLinkedin />}
      >LinkedIn</Button>
      <Button
        bg="black"
        borderRadius={0}
        color="white"
        as="a"
        href='https://github.com/GeneralDynamicInformationSoftware'
        target={'_blank'}
        leftIcon={<FaGithub />}
      >GitHub</Button>
    </Stack>
  )
}

export default MobileFooter;