import { Flex, Image } from "@chakra-ui/react";
import masterBannerLogo from '../assets/master-banner-logo.png';

const AppBar = () => {
  return (
    <Flex
      zIndex={1}
      position="fixed"
      top="0"
      left="0"
      right="0"
      height="70px"
      bg="black"
      bgGradient="linear-gradient(0deg, #FFFFFF 50%, #D9D9D9 100%)"
      p="10px"
      shadow="md"
    >
      <Image
        w="200px"
        src={masterBannerLogo}
      />
    </Flex>

  )
}

export default AppBar;