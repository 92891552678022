import { Flex, Image, HStack, Stack } from '@chakra-ui/react'
import { useMediaQuery } from "react-responsive";
import React from 'react'
import SimpleFade from '../animations/SimpleFade';
import masterLogo from '../assets/master-logo.svg';
import Footer from '../components/Footer';
import AppBar from '../components/LandingBar';
import AboutCard from '../components/AboutCard';
import ContactCard from '../components/ContactCard';


const Landing = React.forwardRef((_: any, ref: any) => {
  const isSmallScreen = useMediaQuery({ query: `(max-width: 70em)` });
  return (
    <>
      <AppBar />
      <HStack
        w="100%"
        minW="100%"
      >
        <Flex
          ref={ref}
          h="100%"
          w="50%"
          justify="center"
          align={"center"}
          overflow={'hidden'}
          bgGradient="linear-gradient(0deg, #FFFFFF 50%, #D9D9D9 100%);"
        >
          <Flex
            flex="1"
            justify="center"
            align={"center"}
            p="20px"
          >
            <SimpleFade
              delay={500}
            >
              <Image src={masterLogo}/>
            </SimpleFade>
          </Flex>
        </Flex>

        <Stack
          p="40px"
          w={isSmallScreen ? "100%" : "50%"}
          justify="center"
          align="center"
          spacing={6}
        >
          <SimpleFade
            delay={1300}
            width="100%"
          >
            <ContactCard />
          </SimpleFade>
          <SimpleFade
            delay={1300}
            width="100%"
          >
            <AboutCard />
          </SimpleFade>
        </Stack>
        
      </HStack>

      <SimpleFade
        delay={1300}
        width="100%"
      >
        <Footer />
      </SimpleFade>
    </>
    
  )
});

export default Landing