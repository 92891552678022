import axios from 'axios';
interface IContactInput {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}

const mailerServiceModule = () => {
  const baseUrl = 'https://gdis-mailer-prod.herokuapp.com/mailer';
  const contact = async ({
    firstName,
    lastName,
    email,
    message,
  }: IContactInput) => {
    try {
      const { data, status } = await axios.post(`${baseUrl}/website/contact`, {
        firstName,
        lastName,
        email,
        message,
      });

      return {
        data,
        status,
        errorMessage: null,
      };
    } catch (e: any) {
      const { response } = e;
      const { data, status } = response;
      return {
        data: null,
        status,
        errorMessage: data?.error ?? e.message,
      };
    }
  };

  return { contact };
};

export default mailerServiceModule;
