import { Flex } from "@chakra-ui/react";
import React, {ReactNode} from "react";

interface IAppCardProps {
  children: ReactNode;
  bg?: string;
  w?: string;
}

const AppCard: React.FC<IAppCardProps> = ({ children, bg = "white", w="100%" }: IAppCardProps) => {
  return (
    <Flex
      p="20px"
      bg={bg}
      borderRadius="40px"
      shadow="md"
      border="1px solid #eaeaea"
      w={w}
    >
      { children }
    </Flex>
  )
}

export default AppCard;