import { Button, Flex, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import SimpleFade from "../animations/SimpleFade";

const links: any[] = []

const LandingBar = () => {
  return (
    <Flex
      position="fixed"
      top="0"
      left="0"
      right="0"
      // w="100%"
      justifyContent={"space-between"}
      p="20px"
    >
          <SimpleFade
            delay={1300}
          >
            <Text
              fontFamily="Times New Roman, serif"
              // mx="5"
              fontWeight={"600"}
              fontSize="16px"
            >
            &copy; GDI Software
            </Text>
          </SimpleFade>
          <SimpleFade
            delay={1300}
          >
          <Flex>
            { links.map((link, i) => (
              <NavLink
                to={link.to}
                key={i}
              >
                <Button
                  key={i}
                  variant={"link"}
                  color="black"
                  mx="5"
                  fontWeight={"600"}
                  fontSize="16px"
                >
                  {link.name}
                </Button>
              </NavLink>
            ))
            }
          </ Flex>
          </SimpleFade>
        </Flex>
  )
}

export default LandingBar;