import { Button, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Stack, Text, useDisclosure } from "@chakra-ui/react";

const PrivacyDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        onClick={onOpen}
        bg="#A0A0A0"
        color="white"
        w="100%"
      >
        Privacy Notice
      </Button>
      <Drawer
        placement="bottom"
        onClose={onClose}
        isOpen={isOpen}
      >
        <DrawerOverlay />
        <DrawerContent>
        <DrawerHeader borderBottomWidth='1px'>Privacy Notice</DrawerHeader>
          <DrawerBody>
          <Stack
            p="20px"
          >
            <Text>We do not use or store your personal information in any way beyond what is necessary to respond to the above inquiry.</Text>
            <Text>If you would prefer to email us directly, we can be reached at:</Text>
            <Text
              fontWeight={"bold"}
              textAlign={"center"}
            
            >office@gdisoftware.com</Text>
          </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default PrivacyDrawer;